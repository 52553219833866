import React from "react"
import { Helmet } from "react-helmet"

import { StaticQuery, graphql } from "gatsby"

export const Seo = ({
  description,
  keywords,
  title,
  image,
  url,
  author,
  language,
  // jobPostTItle,
  // jobPostDescription,
  // jobPostDate,
}) => {
  // const [jsonLdJobPosted] = useState({
  //   "@context": "https://schema.org/",
  //   "@type": "JobPosting",
  //   title: jobPostTItle,
  //   description: `<p>${jobPostDescription}</p>`,
  //   datePosted: jobPostDate,
  //   jobLocation: {
  //     "@type": "Place",
  //     address: {
  //       "@type": "PostalAddress",
  //       streetAddress: "Punto 45, Boulevard A Zacatecas 845",
  //       addressLocality: "Aguascalientes",
  //       addressRegion: "MX",
  //       postalCode: "20116",
  //       addressCountry: "MX",
  //     },
  //   },
  //   employmentType: "FULL_TIME",

  //   hiringOrganization: {
  //     "@type": "Organization",
  //     name: "quick global mexico",
  //     sameAs: "https://www.919mexico.com/",
  //   },

  // })

  return (
    <StaticQuery
      query={detailsSeo}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        //const metaImage = image || data.site.siteMetadata.image;
        const metaImage = "/static/919mexicoBanner-7e762961cf2fa2f00c5f2dfcf7443295.png";
        const metaKeywords = keywords || []
        const metaAuthor = author || data.site.siteMetadata.author
        return (
          <Helmet
            htmlAttributes={{
              lang: language ? language : "ja",
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: `https://www.919mexico.com${metaImage}`,
              },
              {
                property: `og:image:width`,
                content: `1200`,
              },
              {
                property: `og:image:height`,
                content: `630`,
              },
              {
                property: `og:image:alt`,
                content: `919mexico thumbnail`,
              },
              {
                property: `og:site_name`,
                content:
                  "メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】",
              },
              {
                property: `og:locale`,
                content: "ja_JP",
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              },
              {
                name: `twitter:site`,
                content: "@quickmexico",
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: `https://www.919mexico.com${metaImage}`,
              },
              {
                property: `twitter:image:width`,
                content: `1200`,
              },
              {
                property: `twitter:image:height`,
                content: `630`,
              },
              {
                property: `twitter:image:alt`,
                content: `919mexico thumbnail`,
              },
              {
                name: `twitter:card`,
                content: "summary_large_image",
              },
              {
                name: `twitter:creator`,
                content: "@quickmexico",
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords,
                  }
                : []
            )}
          >
            {/* <script type="application/ld+json">
              {JSON.stringify(jsonLdJobPosted)}
            </script> */}
          </Helmet>
        )
      }}
    />
  )
}

const detailsSeo = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`

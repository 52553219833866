import React from "react"
import { Seo } from "../components/seo"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
const Documents = () => {
  const arrow = require("../images/documents/arrow_down.png")
  const documents = require("../images/documents/documents.png")
  const doc1 =
    "https://www.mediafire.com/file_premium/tn50sqqaqml0kr8/%E5%B1%A5%E6%AD%B4%E6%9B%B8%25E3%2583%2595%25E3%2582%25A9%25E3%2583%25BC%25E3%2583%259E%25E3%2583%2583%25E3%2583%2588.doc/file"
  const doc2 =
    "https://www.mediafire.com/file_premium/4n7kew6f2fskumn/%E3%82%B9%E3%83%9A%E3%82%A4%E3%83%B3%E8%AA%9ECV%25E3%2583%2595%25E3%2582%25A9%25E3%2583%25BC%25E3%2583%259E%25E3%2583%2583%25E3%2583%2588%25EF%25BC%2591.docx/file"
  const doc3 =
    "https://www.mediafire.com/file_premium/9z3a7f0v7376dw4/%E3%82%B9%E3%83%9A%E3%82%A4%E3%83%B3%E8%AA%9ECV%25E3%2583%2595%25E3%2582%25A9%25E3%2583%25BC%25E3%2583%259E%25E3%2583%2583%25E3%2583%2588%25EF%25BC%2592.docx/file"
  const doc4 =
    "https://www.mediafire.com/file_premium/5jbcbsbj9mzjnxo/%E8%81%B7%E5%8B%99%E7%B5%8C%E6%AD%B4%E6%9B%B8_%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%83%E3%83%88.doc/file"
  const doc5 =
    "https://www.mediafire.com/file_premium/supmgq44ep5xr0w/%E8%81%B7%E5%8B%99%E7%B5%8C%E6%AD%B4%E6%9B%B8%25E3%2582%25B5%25E3%2583%25B3%25E3%2583%2597%25E3%2583%25AB.doc/file"
  return (
    <>
      <Seo title={`Documents`} />

      <section className="container documents">
        <div className="row">
          <div className="col-lg-9">
            <div className="documents_title">
              <SectionTitle>フォーマット一覧 </SectionTitle>
              <hr className="u-line-bottom" />
            </div>
            <div className="documents_content">
              <p className="documents_subtext">
                各種書類はダウンロードしてご利用ください。
              </p>
              <div className="documents_download">
                <div className="documents_download_sec1">
                  <img src={documents} alt="documents" />
                  <p>履歴書フォーマット</p>
                </div>
                <div className="documents_download_sec2">
                  <img src={arrow} alt="down-arrow" />
                  <a href={doc1} download>
                    ダウンロード
                  </a>
                </div>
              </div>
              <div className="documents_download">
                <div className="documents_download_sec1">
                  <img src={documents} alt="documents" />
                  <p>スペイン語CVフォーマット1</p>
                </div>
                <div className="documents_download_sec2">
                  <img src={arrow} alt="down-arrow" />
                  <a href={doc2} download>
                    ダウンロード
                  </a>
                </div>
              </div>
              <div className="documents_download">
                <div className="documents_download_sec1">
                  <img src={documents} alt="documents" />
                  <p>スペイン語CVフォーマット2</p>
                </div>
                <div className="documents_download_sec2">
                  <img src={arrow} alt="down-arrow" />
                  <a href={doc3} download>
                    ダウンロード
                  </a>
                </div>{" "}
              </div>
              <div className="documents_download">
                <div className="documents_download_sec1">
                  <img src={documents} alt="documents" />
                  <p>職務経歴書フォーマット</p>
                </div>
                <div className="documents_download_sec2">
                  <img src={arrow} alt="down-arrow" />
                  <a href={doc4} download>
                    ダウンロード
                  </a>
                </div>
              </div>
              <div className="documents_download">
                <div className="documents_download_sec1">
                  <img src={documents} alt="documents" />
                  <p>職務経歴書サンプル</p>
                </div>
                <div className="documents_download_sec2">
                  <img src={arrow} alt="down-arrow" />
                  <a href={doc5} download>
                    ダウンロード
                  </a>
                </div>
              </div>
              <Contact />
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default Documents
